import React, { useEffect, useState, useReducer, lazy, Suspense } from "react"
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Axios from "axios"
import { useSelector } from "react-redux"

Axios.defaults.baseURL = "http://manufactureback.evotechies.com"
//Axios.defaults.baseURL = "http://localhost:32824"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));



const App = () => {

  const loggedin = useSelector((state) => state.loggedIn)
  useEffect(() => {
    if (!loggedin) {
      localStorage.removeItem("laravelauth")
    }
  }, [loggedin])

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <Route path="/" name="Home" >
            {loggedin ? <TheLayout /> : <Login />}
          </Route>
        </Switch>
      </React.Suspense>
    </HashRouter>
  )

}

export default App
